import React from "react";
import Layout from "../../components/Layout";

export default function librosElectronicos() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(../../images/sector_educativo/1.jpg)`}}
                    >
                        <h1>Libros Electrónicos</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Portafolio de soluciones bibliográficas enfocadas a diversos ámbitos del quehacer
                            disciplinario, con una cobertura temática exhaustiva y diversidad documental. Integra una
                            amplia disponibilidad de libros de texto, manuales para la práctica profesional, obras de
                            consulta, series y monografías especializadas.
                            <br/>
                            Contamos con cerca de 1,000,000 de títulos diversificados en las siguientes plataformas en
                            línea:
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}